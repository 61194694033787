
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });

    const verifyAuth = () => {
      store.dispatch(Actions.VERIFY_AUTH).then(() => {
        //router.push({ name: "dashboard" });
      });
    };

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

        // Send login request
      const resp = await store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            router.push({ name: process.env.VUE_APP_DEFAULT_PAGE });
          })
          .catch(() => {
            let errText = "";
            const err = store.getters.getErrors;
            if (err.code == 420) {
              for (const property in err.error) {
                //console.log(`${property}: ${err[property]}`);
                errText += `${err.error[property]} `;
              }
            } else if (err.code == 401) {
              errText = store.getters.getErrors.error;

            } else if (err.code == 425) {
              errText = 'Your email is not verified';

            } else if (err.code == 426) {
              errText = 'Your account is not active';
            }

            Swal.fire({
              text: errText,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");

    };

    return {
      verifyAuth,
      onSubmitLogin,
      login,
      submitButton,
    };
  },

  mounted() {
    this.$nextTick(function () {
      this.verifyAuth();
    });
  },
});
